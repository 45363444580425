<template>
  <div class="common_alert">
    <div class="common_alert_box">
      <slot name="contents"></slot>
      <div class="common_alertbox_btngroup">
        <button class="common_alertbox_yes" @click="clickConfirmYes">
          <slot name="confirmyes"> </slot>
        </button>
        <button class="common_alertbox_no" @click="clickConfirmNo">
          <slot name="confirmno"></slot>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CommonConfirmVertical',
  emits: ['confirm-no', 'confirm-yes'],
  methods: {
    clickConfirmNo() {
      this.$emit('confirm-no');
    },
    clickConfirmYes() {
      this.$emit('confirm-yes');
    }
  }
};
</script>
<style scoped src="@/assets/css/common/commonconfirmvertical.css"></style>
