<template>
  <div class="common_alert">
    <div class="common_alert_box">
      <div class="common_alert_contents">
        <slot name="contents"> </slot>
      </div>

      <div class="common_alertbox_btngroup">
        <button class="common_alertbox_btn" @click="onCloseModal">
          <slot name="cancelbtn">아니오</slot>
        </button>
        <button class="common_alertbox_btn confirm" @click="onConfirmModal">
          <slot name="confirmbtn">네</slot>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CommonConfirm',
  props: {
    contents: {
      type: String,
      default: null,
      required: false
    },
    confirmid: {
      type: String,
      default: null,
      required: true
    }
  },
  emits: ['close-modal', 'confirm-modal'],
  methods: {
    onCloseModal() {
      this.$emit('close-modal');
    },
    onConfirmModal() {
      this.$emit('confirm-modal');
    }
  }
};
</script>
<style scoped src="@/assets/css/common/commonconfirm.css"></style>
