import { render, staticRenderFns } from "./CommonConfirmVertical.vue?vue&type=template&id=72030750&scoped=true"
import script from "./CommonConfirmVertical.vue?vue&type=script&lang=js"
export * from "./CommonConfirmVertical.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/common/commonconfirmvertical.css?vue&type=style&index=0&id=72030750&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72030750",
  null
  
)

export default component.exports